import axios from 'axios'
import { Message } from 'element-ui'

const service = axios.create({
    timeout:10000,
    baseURL:'https://lt.fangyiyun.com/api'
})

service.interceptors.request.use(
    config=>{
        console.log('++++++++++')
        return config;
    },
    error=>{
        console.log('----');
        return Promise.reject(error)
    }
    
)

service.interceptors.response.use(
    response=>{
        const res = response.data;
        console.log('resp',res)
        if(res.code==200){
            return res;
        }else{
            console.log('未知错误',res)
            let message = res.message||'未知错误'
            Message({
                message,
                type:'error',
                duration:5 *1000
            })
            return Promise.reject(
                new Error(res.message||'未知错误')
            )
        }
    },
    error=>{
        console.log('error',error)
        if(error.response){
            Message({
                message:'服务器繁忙，请稍后再试...',
                type:'error',
                duration:3 * 1000
            })
            return Promise.reject('服务器繁忙，请稍后再试...')
        }else{
            return Promise.reject(error.message)
        }
    }
)

export default service;