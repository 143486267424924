<template>
  <div class="home">
    <!-- 侧边固定栏 -->
    <div class="right-fixed-block flex-col-center">
      <div class="block-1 flex-col-center-sb">
        <img src="@/assets/icons/wx-empty.svg" />
        <div class="gap"></div>
        <img src="@/assets/icons/phone.svg" />
      </div>
      <div class="block-2">
        <img src="@/assets/icons/logo-circle.svg" />
        在线咨询
      </div>
      <div class="block-3 flex-center-center" @click="goTop">
        <img src="@/assets/icons/arrow-up-grey.svg" />
      </div>
    </div>
    <!-- 顶部logo 导航栏 -->
    <div class="nav flex-center-sb">
      <div class="nav-left flex-center-center">
        <img src="@/assets/icons/logo.svg" class="logo" />
        <span class="title">抖音地产数字化平台</span>
      </div>
      <div class="nav-right flex-center-center">
        <div class="menu-item">主页</div>
        <div class="menu-item">模型广场</div>
        <div class="menu-item">模型广场</div>
        <div class="menu-item">模型广场</div>
        <div class="menu-item">联系我们</div>
      </div>
    </div>
    <!-- 头部轮播模块 -->
    <el-carousel trigger="click" height="62.44rem" class="banner">
      <el-carousel-item class="banner-item">
        <div class="flex-col">
          <img src="@/assets/imgs/swiper-img.png" class="banner-bg" />
          <img src="@/assets/imgs/cloud.png" class="cloud" />
          <span class="title">抖音地产数字化平台</span>
          <span class="sub-title">
            简单好用/多专业实时协同设计/拥有独立的数据库结构
          </span>
          <div class="btns flex-center">
            <div class="btn flex-center-center">免费注册</div>
            <div class="btn flex-center-center" @click="download">下载免费版</div>
          </div>
          <div class="link flex-center">
            <span>回顾发布会</span>
            <img src="@/assets/imgs/arrow-right.png" class="arrow-right" />
          </div>
          <div class="cards flex-center-sb">
            <div class="card-item flex-col-sb">
              <img src="@/assets/icons/协议说明.svg" class="card-icon" />
              <span class="card-title">协议说明</span>
              <span class="card-sub-title">木兰公共许可证</span>
            </div>
            <div class="card-item flex-col-sb">
              <img src="@/assets/icons/开源承诺.svg" class="card-icon" />
              <span class="card-title">开源承诺</span>
              <span class="card-sub-title">续开源免费，并且持续迭代更新</span>
            </div>
            <div class="card-item flex-col-sb">
              <img src="@/assets/icons/代码位置.svg" class="card-icon" />
              <span class="card-title">代码位置</span>
              <span class="card-sub-title">itee（前端部分丨Java部分）</span>
            </div>
            <div class="card-item flex-col-sb">
              <img src="@/assets/icons/相关文章.svg" class="card-icon" />
              <span class="card-title">相关文章</span>
              <span class="card-sub-title">SChina丨知乎</span>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- 版本选择模块 -->
    <div class="version-block flex-col-center">
      <span class="title">根据自身需求，选择适合您的版本</span>
      <span class="sub-title">
        从初创公司到500强，可扩展到任何公司，大幅度缩减你的的运营成本
      </span>
      <div class="version-perv flex-center-center" @click="versionGoPerv">
        <img src="@/assets/icons/arrow-right-grey.svg" />
      </div>
      <div class="version-next flex-center-center" @click="versionGoNext">
        <img src="@/assets/icons/arrow-right-grey.svg" />
      </div>
      <div class="version-list" id="version-list">
        <div class="version-item" v-for="(item, i) in 6" :key="i">
          <div class="btn flex-center-center" v-if="i == 0">免费试用</div>
          <div class="btn type2 flex-center-center" v-else>立即咨询</div>
        </div>
      </div>
      <span class="more flex-center">
        更对对比详情
        <img src="@/assets/icons/arrow-right-grey.svg" />
      </span>
    </div>
    <!-- 服务支持介绍模块 -->
    <div class="service-block flex-col-center">
      <span class="title">您的数字化旅程，有我们始终如一的陪伴</span>
      <div class="service">
        <img src="@/assets/imgs/service.png" />
        <span class="consult flex-center">
          立即咨询
          <img src="@/assets/icons/arrow-right-blue.svg" />
        </span>
      </div>
      <div class="service-2 flex-center-sb">
        <img src="@/assets/imgs/authentication.png" class="authentication" />
        <img src="@/assets/imgs/programme.png" class="programme" />
      </div>
      <div class="data-block flex-col-center">
        <span class="title">源于实践的数据积累，持续助力每家企业</span>
        <img src="@/assets/imgs/data-change.png" />
        <span class="tips"
          >*数据来源 房易云产品运营团队于 2022.06.07-2023.06.07 期间针对 1600
          家不同行业企业客户调研成果</span
        >
      </div>
    </div>
    <!-- 产品介绍模块 -->
    <div class="produce-block flex-col">
      <span class="title">相关产品介绍</span>
      <span class="sub-title">
        支持就看见可洁可净可洁可净看卡。 支持就看见可洁可净可洁可净看卡。
        支持就看见可洁可净可洁可净看卡。 支持就看见可洁可净可洁可净看卡。
        支持就看见可洁可净可洁可净看卡。 支持就看见可洁可净可洁可净看卡。
        支持就看见可洁可净可洁可净看卡。 支持就看见可洁可净可洁可净看卡。
        支持就看见可洁可净可洁可净看卡。 支持就看见可洁可净可洁可净看卡。
        支持就看见可洁可净可洁可净看卡。
      </span>
      <div class="content-box">
        <img src="@/assets/imgs/introduce.png" />
        <div class="btns flex-center">
          <div class="btn flex-center-center">立即体验</div>
          <div class="btn flex-center-center">了解详情</div>
        </div>
      </div>
    </div>
    <!-- 房易云能力模块 -->
    <div class="power-block flex-col-center">
      <span class="title">房易云的能力，远不止于此</span>
      <span class="sub-title">不断进化的功能，等你来体验</span>
      <img src="@/assets/imgs/power.png" class="power-img" />
      <div class="btns flex-center">
        <div class="btn flex-center-center">体验所有功能</div>
        <div class="btn flex-center-center">了解更多能力</div>
      </div>
    </div>
    <!-- 新闻资讯模块 -->
    <div class="news-block flex-col-center">
      <span class="title">新闻资讯</span>
      <span class="sub-title">
        从初创公司到500强，从初创公司到500强从初创公司到500强从初创公成本
      </span>
      <div class="news-list" id="news-list">
        <div class="news-item flex-col" v-for="(item, i) in 6" :key="i">
          <img src="@/assets/imgs/news-img.png" class="news-img" />
          <div class="news-content flex-col">
            <span class="title">重磅联名重磅联名</span>
            <span class="ctx">
              在近期举办的在2021年秋季发布会上，面向年轻消费者的第三方
              在近期举办的在2021年秋季发布会上，面向年轻消费者的第三方
              在近期举办的在2021年秋季发布会上，面向年轻消费者的第三方
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部footer -->
    <div class="footer flex-col-center">
      <div class="title">选择房易云 让连接更美好</div>
      <div class="main-btns flex-center">
        <div class="btn-consult flex-center-center">企业咨询</div>
        <div class="btn-register flex-center-center">立即注册</div>
      </div>
      <div class="flex-center-sb">
        <div class="block-1 flex-col">
          <img src="@/assets/icons/logo.svg" class="logo" />
          <div class="icons flex-center">
            <img src="@/assets/icons/location.svg" class="icon" />
            <img src="@/assets/icons/wechat.svg" class="icon" />
          </div>
          <div class="qrcode flex-center">
            <div class="flex-col-center">
              <img src="@/assets/imgs/wx-qr.png" class="code" />
              关注公众号
            </div>
            <div class="flex-col-center">
              <img src="@/assets/imgs/wx-qr.png" class="code" />
              微信交流群
            </div>
          </div>
          <span class="call-us">联系我们</span>
          <span class="call-us-phone">400-0809-893</span>
          <span class="email">客户联系邮箱：2323232323@163.com</span>
          <span class="address"
            >这是一个地址这是一个地址这是一个地址这是一个地址</span
          >
        </div>
        <div class="block-2 flex-sb">
          <div class="flex-col">
            <span class="tit">产品</span>
            <span class="label">帮助中心</span>
            <span class="label">产品定价</span>
            <span class="label">意见反馈</span>
            <div class="flex-col" style="margin-top: 2.5rem">
              <span class="tit">产品</span>
              <span class="label">模板训练营</span>
              <span class="label">房易云认证</span>
            </div>
          </div>
          <div class="flex-col">
            <span class="tit">方案</span>
            <span class="label">解决方案</span>
            <span class="label">解决方案</span>
            <span class="label">解决方案</span>
            <span class="label">解决方案</span>
            <span class="label">解决方案</span>
            <span class="label">解决方案</span>
          </div>
          <div class="flex-col">
            <span class="tit">进一步了解</span>
            <span class="label">关于我们</span>
            <span class="label">向团队介绍</span>
            <span class="label">隐私政策</span>
            <span class="label">服务条款</span>
          </div>
        </div>
        <div class="block-3">
          <div class="title-row flex-sb">
            <span>资讯</span>
            <span class="more flex-center">
              更多文章
              <img src="@/assets/icons/arrow-right-grey-fill.svg" />
            </span>
          </div>
          <div class="news-list">
            <div class="news-item flex-col" v-for="(item, i) in 3" :key="i">
              <span class="tit">房易云推荐官火热招募中</span>
              <span class="datetime">2023-03-24</span>
            </div>
          </div>
        </div>
      </div>
      <img src="@/assets/imgs/footer-logos.png" class="footer-logos" />
      <img src="@/assets/imgs/bottom-gap.png" class="bottom-gap" />
      <div class="cor-info flex-center-sb">
        <div class="flex-center" style="font-size: 0.63rem; color: #919191">
          <span>©2020 Fangyiyun(Jiang su) Software Co.Ltd</span>
          <span class="flex-center" style="margin-left: 4.56rem">
            <img
              src="@/assets/icons/bage.svg"
              style="width: 0.69rem; height: 0.69rem; margin-right: 0.13rem"
            />
            苏公网安备05170000000001号
          </span>
        </div>
        <div class="flex-center">
          <img
            src="@/assets/icons/wx.svg"
            style="width: 1.63rem; height: 1.63rem; margin-right: 1.44rem"
          />
          <img
            src="@/assets/icons/video.svg"
            style="width: 1.63rem; height: 1.63rem"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getUrl from '@/api/api'
export default {
  name: "HomeView",
  data() {
    return {};
  },
  methods: {
    download(){
      window.location.href='http://www.fangyiyun.com/updater/win32/房易云房源系统 Setup 1.1.9.exe'
      // getUrl().then(res=>{
      //     //window.location.href='http://www.fangyiyun.com/updater/win32/房易云房源系统 Setup 1.1.0.exe'
      //     console.log('getUrl',res)
      // })
      
    },
    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 可选，平滑滚动效果
      });
    },
    dragMoveVersionList() {
      let container = document.getElementById("version-list");
      let startX, endX;
      let ev = null;

      container.addEventListener("mousedown", function (e) {
        this.style.scrollBehavior = "auto";
        startX = e.pageX;
        container.addEventListener(
          "mousemove",
          (ev = function (e) {
            e.preventDefault(); // 防止页面滚动
            endX = e.pageX;
            this.scrollLeft += startX - endX;
            startX = endX;
          })
        );
      });
      container.addEventListener("mouseup", function (e) {
        this.style.scrollBehavior = "smooth";
        container.removeEventListener("mousemove", ev);
      });
      container.addEventListener("mouseout", function (e) {
        this.style.scrollBehavior = "smooth";
        container.removeEventListener("mousemove", ev);
      });
    },
    dragMoveNewsList() {
      let container = document.getElementById("news-list");
      let startX, endX;
      let ev = null;

      container.addEventListener("mousedown", function (e) {
        startX = e.pageX;
        container.addEventListener(
          "mousemove",
          (ev = function (e) {
            e.preventDefault(); // 防止页面滚动
            endX = e.pageX;
            this.scrollLeft += startX - endX;
            startX = endX;
          })
        );
      });
      container.addEventListener("mouseup", function (e) {
        container.removeEventListener("mousemove", ev);
      });
      container.addEventListener("mouseout", function (e) {
        container.removeEventListener("mousemove", ev);
      });
    },
    versionGoPerv() {
      let container = document.getElementById("version-list");
      container.scrollLeft -= 410;
    },
    versionGoNext() {
      let container = document.getElementById("version-list");
      container.scrollLeft += 410;
    },
  },
  mounted() {
    this.dragMoveVersionList();
    this.dragMoveNewsList();
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  overflow-x: hidden;

  .right-fixed-block {
    position: fixed;
    right: 6.06rem;
    top: 25rem;
    z-index: 9;

    .block-1 {
      padding: 0.75rem;
      box-sizing: border-box;
      width: 2.94rem;
      height: 6.06rem;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.5rem 0.06rem rgba(0, 0, 0, 0.11);
      border-radius: 1.5rem;

      img {
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
      }

      .gap {
        width: 2.13rem;
        height: 0.19rem;
        background: #f6f6f6;
      }
    }

    .block-2 {
      margin-top: 0.88rem;
      padding: 0.31rem 0.19rem;
      box-sizing: border-box;
      width: 2.94rem;
      height: 8.81rem;
      background: linear-gradient(
        180deg,
        #84b3ff 0%,
        #4b82ff 45%,
        #6599ff 100%
      );
      box-shadow: 0rem 0rem 0.38rem 0.06rem rgba(0, 0, 0, 0.3);
      border-radius: 1.5rem;
      font-size: 0.81rem;
      color: #ffffff;
      letter-spacing: 0.25rem;
      writing-mode: vertical-lr;
      cursor: pointer;

      img {
        width: 2.56rem;
        height: 2.56rem;
      }
    }

    .block-3 {
      margin-top: 1.25rem;
      width: 2.94rem;
      height: 2.94rem;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.5rem 0.06rem rgba(0, 0, 0, 0.11);
      border-radius: 1.5rem;
      cursor: pointer;

      img {
        width: 0.88rem;
        height: 0.53rem;
      }
    }
  }

  .nav {
    padding: 0 15.06rem 0 16.24rem;
    box-sizing: border-box;
    width: 100%;
    height: 5.13rem;
    background: linear-gradient(90deg, #f6f7fe 0%, #f9fafe 100%);

    .nav-left {
      .logo {
        width: 10.44rem;
        height: 2.63rem;
      }
      .title {
        margin-left: 3.75rem;
        font-weight: bold;
        font-size: 0.88rem;
      }
    }
    .nav-right {
      .menu-item {
        font-weight: bold;
        font-size: 0.88rem;
        cursor: pointer;

        &:not(:first-child) {
          margin-left: 3.75rem;
        }

        &:hover {
          color: #2d5ffe;
        }
      }
    }
  }

  .banner {
    width: 100%;
    overflow: hidden;
    .banner-item {
      position: relative;
      padding-left: 16.44rem;
      box-sizing: border-box;
    }
    .banner-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 62.44rem;
      z-index: -1;
    }
    .cloud {
      position: absolute;
      top: 3.38rem;
      right: 15.63rem;
      width: 26.33rem;
      height: 26.39rem;
      z-index: 1;
      animation-name: upanddown;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }

    @keyframes upanddown {
      0% {
        top: 3.38rem;
      }
      100% {
        top: 4.38rem;
      }
    }

    .title {
      margin-top: 9.13rem;
      font-weight: bold;
      font-size: 3.5rem;
    }
    .sub-title {
      margin-top: 1.56rem;
      font-size: 1.13rem;
    }

    .btns {
      margin-top: 2.81rem;
      .btn {
        width: 13.63rem;
        height: 4.63rem;
        font-weight: bold;
        font-size: 1.5rem;
        color: #ffffff;
        background: linear-gradient(90deg, #2d5ffe 0%, #4eedfc 100%);
        border-radius: 1.13rem;
        cursor: pointer;

        &:nth-child(2) {
          margin-left: 2rem;
          color: #2e66fd;
          border: 0.13rem solid #2d61fd;
          background: #ffffff;
        }
      }
    }

    .link {
      margin-top: 2.31rem;
      font-size: 1.38rem;
      color: #4c6395;
      cursor: pointer;

      > img {
        margin-left: 0.75rem;
        width: 1.05rem;
        height: 0.89rem;
      }
    }

    .cards {
      position: absolute;
      bottom: 7.5rem;
      left: 3.69rem;
      width: calc(100% - 3.69rem - 3.56rem);

      .card-item {
        padding: 1.38rem 2rem;
        box-sizing: border-box;
        width: calc((100% - 2.25rem) / 4);
        height: 9.75rem;
        background-color: #fff;
        background-image: url("@/assets/imgs/card-bg.png");
        background-size: cover;
        box-shadow: 0rem 0.25rem 0.75rem 0.06rem rgba(0, 0, 0, 0.05);
        border-radius: 0.25rem;
        cursor: pointer;

        .card-icon {
          width: 2.06rem;
          height: 2.06rem;
        }
        .card-title {
          font-weight: bold;
          font-size: 1.56rem;
        }
        .card-sub-title {
          font-size: 1.13rem;
          color: #bebebe;
        }

        &:hover{
          transform: scale(1.03);
        }
      }
    }
  }

  .version-block {
    position: relative;
    width: 100%;
    height: 52.38rem;

    .title {
      margin-top: 5rem;
      font-weight: bold;
      font-size: 2.13rem;
    }

    .sub-title {
      margin-top: 1.5rem;
      font-size: 1.25rem;
      color: #919191;
    }

    .version-perv {
      position: absolute;
      top: 28rem;
      left: 10rem;
      width: 2rem;
      height: 2rem;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.5rem 0.06rem rgba(0, 0, 0, 0.11);
      border-radius: 50%;
      cursor: pointer;

      img {
        width: 0.38rem;
        height: 0.88rem;
        transform: rotate(180deg);
      }
    }
    .version-next {
      position: absolute;
      top: 28rem;
      right: 10rem;
      width: 2rem;
      height: 2rem;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.5rem 0.06rem rgba(0, 0, 0, 0.11);
      border-radius: 50%;
      cursor: pointer;

      img {
        width: 0.38rem;
        height: 0.88rem;
      }
    }

    .version-list {
      margin-top: 5rem;
      width: calc(100% - 32.5rem);
      height: 26.75rem;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE/Edge */
      user-select: none; /* 标准语法 */
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
        display: none;
      }

      .version-item {
        display: inline-block;
        position: relative;
        margin-right: 1.25rem;
        width: 25.44rem;
        height: 26.75rem;
        border-radius: 0.5rem;
        background-image: url("@/assets/imgs/ver-card.png");
        background-size: cover;
        cursor: pointer;

        .btn {
          position: absolute;
          bottom: 1.88rem;
          left: 2.38rem;
          width: 20.69rem;
          height: 2.88rem;
          background: #3677ec;
          border-radius: 0.5rem;
          font-weight: bold;
          font-size: 0.75rem;
          color: #ffffff;
        }

        .type2 {
          color: #3677ec;
          background: transparent;
          border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
          border: 0.09rem solid #3677ec;
        }
      }
    }

    .more {
      margin-top: 3.44rem;
      font-size: 1.13rem;
      color: #919191;
      cursor: pointer;

      img {
        margin-left: 0.56rem;
        width: 0.53rem;
        height: 0.88rem;
      }
    }
  }

  .service-block {
    padding: 0 16.25rem;
    box-sizing: border-box;
    width: 100%;
    height: 97.57rem;
    .title {
      margin-top: 4rem;
      font-weight: bold;
      font-size: 2.13rem;
    }

    .service {
      position: relative;
      margin-top: 6rem;
      margin-bottom: 1.63rem;
      width: 100%;
      height: 25.25rem;

      img {
        width: 100%;
        height: 100%;
      }

      .consult {
        position: absolute;
        left: 6.06rem;
        bottom: 4rem;
        font-size: 1rem;
        color: #4689fd;
        cursor: pointer;

        img {
          margin-left: 0.38rem;
          width: 0.53rem;
          height: 0.88rem;
        }
      }
    }

    .service-2 {
      width: 100%;

      .authentication {
        width: 51rem;
        height: 37rem;
      }

      .programme {
        width: 34rem;
        height: 37rem;
      }
    }

    .data-block {
      position: relative;
      .title {
        margin-top: 3.5rem;
        font-weight: bold;
        font-size: 2.13rem;
      }

      img {
        margin-top: 1.94rem;
        width: 87.5rem;
        height: 7.75rem;
      }

      .tips {
        position: absolute;
        right: 2.44rem;
        bottom: -2.25rem;
        font-size: 0.94rem;
        color: #919191;
      }
    }
  }

  .produce-block {
    padding: 0 16.25rem;
    box-sizing: border-box;
    width: 100%;
    height: 60.13rem;

    .title {
      margin-top: 2rem;
      font-weight: bold;
      font-size: 3rem;
    }

    .sub-title {
      margin-top: 2.88rem;
      font-size: 1.19rem;
      color: #bebebe;
      line-height: 1.75rem;
    }

    .content-box {
      position: relative;
      margin-top: 5rem;

      img {
        width: 100%;
      }

      .btns {
        position: absolute;
        left: 5.38rem;
        bottom: 4.38rem;

        .btn {
          width: 10.5rem;
          height: 3.5rem;
          background: #579dfd;
          border-radius: 0.5rem;
          font-size: 0.94rem;
          color: #ffffff;
          box-sizing: border-box;
          cursor: pointer;

          &:nth-child(2) {
            margin-left: 1.25rem;
            color: #579dfd;
            background: #ffffff;
            border: 0.06rem solid #579dfd;
          }
        }
      }
    }
  }

  .power-block {
    padding: 0 16.25rem;
    box-sizing: border-box;
    width: 100%;
    height: 72.56rem;

    .title {
      margin-top: 2rem;
      font-weight: bold;
      font-size: 2.13rem;
    }

    .sub-title {
      margin-top: 1.5rem;
      font-size: 1.25rem;
      color: #919191;
    }

    .power-img {
      margin-top: 5rem;
      width: 100%;
      height: 47.56rem;
    }

    .btns {
      margin-top: 3.75rem;

      .btn {
        width: 13.25rem;
        height: 3.56rem;
        background: #3981f6;
        border-radius: 0.25rem;
        font-weight: bold;
        font-size: 1rem;
        color: #ffffff;
        box-sizing: border-box;
        cursor: pointer;

        &:nth-child(2) {
          margin-left: 1rem;
          color: #3981f6;
          background: #ffffff;
          border: 0.06rem solid #3981f6;
        }
      }
    }
  }
  .news-block {
    padding: 0 16.25rem;
    box-sizing: border-box;
    width: 100%;
    height: 49.75rem;

    .title {
      margin-top: 2rem;
      font-weight: bold;
      font-size: 2.13rem;
    }

    .sub-title {
      margin-top: 1.5rem;
      font-size: 1.25rem;
      color: #919191;
    }

    .news-list {
      margin-top: 5rem;
      width: 100%;
      height: 26.75rem;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE/Edge */
      user-select: none; /* 标准语法 */

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
        display: none;
      }

      .news-item {
        display: inline-block;
        margin-right: 1.25rem;
        width: 25.44rem;
        height: 26.75rem;
        background: linear-gradient(111deg, #f4f7fd 0%, #f4f7fd 100%);
        border-radius: 0.5rem;

        .news-img {
          width: 100%;
          height: 15.88rem;
        }

        .news-content {
          padding: 1.63rem 1.88rem;
          box-sizing: border-box;

          .title {
            margin: 0;
            font-weight: bold;
            font-size: 1.06rem;
          }

          .ctx {
            margin-top: 0.88rem;
            font-size: 0.75rem;
            color: #747474;
            white-space: normal;
          }
        }
      }
    }
  }

  .footer {
    width: 100%;
    height: 57.94rem;
    background: url("@/assets/imgs/footer-bg.png");
    background-size: cover;

    .title {
      margin-top: 5.19rem;
      font-weight: bold;
      font-size: 2.19rem;
    }

    .main-btns {
      margin-top: 2.19rem;
      margin-bottom: 5.69rem;

      .btn-consult {
        width: 13.06rem;
        height: 3.25rem;
        border-radius: 0.25rem;
        border: 0.06rem solid #ff0000;
        font-size: 1rem;
        color: #ff0000;
        cursor: pointer;
      }

      .btn-register {
        margin-left: 1.88rem;
        width: 13.06rem;
        height: 3.25rem;
        background: #ff0000;
        border-radius: 0.25rem;
        font-size: 1rem;
        color: #ffffff;
        cursor: pointer;
      }
    }

    .block-1 {
      width: 18rem;
      height: 23rem;

      .logo {
        width: 9.69rem;
        height: 2.44rem;
      }

      .icons {
        margin-top: 0.88rem;
        .icon {
          width: 1.88rem;
          height: 1.88;

          &:nth-child(1) {
            margin-right: 0.63rem;
          }
        }
      }

      .qrcode {
        margin-top: 1.63rem;
        font-size: 0.75rem;

        div:nth-child(1) {
          margin-right: 1.25rem;
        }

        .code {
          margin-bottom: 0.31rem;
          width: 6.25rem;
          height: 6.25rem;
          border-radius: 0.25rem;
        }
      }

      .call-us {
        margin-top: 1.88rem;
        font-size: 0.75rem;
      }

      .call-us-phone {
        margin-top: 0.25rem;
        font-weight: bold;
        font-size: 1.25rem;
      }

      .email {
        margin-top: 1.06rem;
        font-size: 0.75rem;
      }

      .address {
        margin-top: 0.31rem;
        font-size: 0.75rem;
        color: #bebebe;
      }
    }

    .block-2 {
      margin-left: 4.75rem;
      width: 35rem;
      height: 23rem;

      .tit {
        margin-bottom: 1.5rem;
        font-weight: bold;
        font-size: 1.13rem;
      }

      .label {
        font-size: 0.88rem;
        color: #5b5b5b;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 1.19rem;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .block-3 {
      margin-left: 9.25rem;
      width: 23rem;
      height: 23rem;

      .title-row {
        font-weight: bold;
        font-size: 1.13rem;
        align-items: baseline;

        .more {
          font-weight: 400;
          font-size: 0.75rem;
          color: #919191;

          img {
            margin-left: 0.44rem;
          }
        }
      }

      .news-list {
        margin-top: 2.44rem;

        .news-item {
          width: 100%;
          height: 4.44rem;
          border-bottom: 0.13rem solid #f6f6f6;

          &:not(:first-child) {
            margin-top: 1.56rem;
          }

          .tit {
            font-size: 0.75rem;
          }

          .datetime {
            margin-top: 0.75rem;
            font-size: 0.75rem;
            color: #bebebe;
          }
        }
      }
    }

    .footer-logos {
      margin-top: 2.75rem;
      width: 90rem;
      height: 7.69rem;
    }

    .bottom-gap {
      margin-top: 1.81rem;
      width: 79.88rem;
      height: 0.25rem;
    }

    .cor-info {
      margin-top: 1.31rem;
      width: 90rem;
    }
  }
}
</style>
